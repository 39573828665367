import React from "react";


import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
function Quality() {

    return (
        <section class="quality-section" id="quality-section" style={{ paddingBottom: "50px", paddingTop: "40px" }}>
            <figure class="offer-toplayer mb-0">
                <img src="./assets/images/offer-toplayer.png" alt="" class="img-fluid" />
            </figure>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="project_content" >
                            <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>Software Testing & QA</h6>
                            <h2> Ensuring Software Excellence</h2>
                            <p>
                                Elevate Your Standards with Our Meticulous Attention to Detail and Rigorous QA Processes.
                            </p>
                            <figure class="offer-circleimage mb-0">
                                <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                </div>

                {/* <div class="row whyus-section ">

                    <div class="owl-theme ">
                      
                           
                            <div class="item px-2">
                                <div class="case-box1 case-box overlay ">
                                    <div class="overlay-image">
                                        <figure class="image mb-0">
                                            <img src="./assets/images/case-image1.jpg" alt="" style={{ width: "100%", height: "100%" }} class="" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <span class="text-white">Design </span>
                                        <h5 class="text-white">Business Analysis  </h5>
                                        <p class="text-white text-size-18">Rerum hic tenetur sapiente...</p>
                                        <div>
                                            <i class="circle fa-thin fa-arrow-right" ></i>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="item px-2">
                                <div class="case-box1 case-box overlay ">
                                    <div class="overlay-image">
                                        <figure class="image mb-0">
                                            <img src="./assets/images/case-image1.jpg" alt="" style={{ width: "100%", height: "100%" }} class="" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <span class="text-white">Design </span>
                                        <h5 class="text-white">Business Analysis  </h5>
                                        <p class="text-white text-size-18">Rerum hic tenetur sapiente...</p>
                                        <div>
                                            <i class="circle fa-thin fa-arrow-right" ></i>
                                        </div>
                                    </div>

                                </div>

                            </div>S
                            <div class="item px-2">
                                <div class="case-box1 case-box overlay ">
                                    <div class="overlay-image">
                                        <figure class="image mb-0">
                                            <img src="./assets/images/case-image1.jpg" alt="" style={{ width: "100%", height: "100%" }} class="" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <span class="text-white">Design </span>
                                        <h5 class="text-white">Business Analysis  </h5>
                                        <p class="text-white text-size-18">Rerum hic tenetur sapiente...</p>
                                        <div>
                                            <i class="circle fa-thin fa-arrow-right" ></i>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="item px-2">
                                <div class="case-box1 case-box overlay ">
                                    <div class="overlay-image">
                                        <figure class="image mb-0">
                                            <img src="./assets/images/case-image1.jpg" alt="" style={{ width: "100%", height: "100%" }} class="" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <span class="text-white">Design </span>
                                        <h5 class="text-white">Business Analysis  </h5>
                                        <p class="text-white text-size-18">Rerum hic tenetur sapiente...</p>
                                        <div>
                                            <i class="circle fa-thin fa-arrow-right"></i>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            
                    </div>

                </div> */}

                <div id="cv-main" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <div id="cv-content" className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t1.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/offer-icon1.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">  <h3>IoT App Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t2.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/offer-icon2.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid px-2" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA"> <h3>Full Cycle Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t3.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content" style={{ alignItems: "center" }}>
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/mobile-test.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">  <h3>Mobile App Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t4.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/saas.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">   <h3>SAAS Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t5.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/testing.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA"> <h3>Exploratory Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t6.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/api.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">  <h3>API Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t7.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/performance.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">    <h3>Performance & Load Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t8.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content" >
                                            <div class="image_content" >
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/manual.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">    <h3>Manual & Automation Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t9.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content" >
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/browser.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">    <h3>Cross Browser &<br /> Device Compatibility Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t10.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/testing.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>

                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">  <h3>Localization Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 " >
                                <div id="cv-card" className="card">
                                    <img id="cv-card-image" src="./assets/images/t2.jpg" alt="..." />
                                    <div id="cv-card-body" className="card-body">
                                        <div class="card-overlay"></div>
                                        <div class="card-content">
                                            <div class="image_content">
                                                <figure class="offer-icon" >
                                                    <img src="./assets/images/cloud-test.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                                </figure>
                                            </div>
                                            <a class="get_started text-white text-decoration-none" href="/software-testing&QA">   <h3 style={{ alignItems: "center" }}>Web & Cloud Apps Testing</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}


export default Quality;
