import React from "react";
import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/owl.carousel.min.css"
import "../assets/css/owl.theme.default.min.css"
import "../assets/css/special-classes.css"
function AboutSection() {

    return (
        <section class="about-section" id="about-section" style={{ paddingTop: "150px", marginBottom: "50px" }}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
                        <div class="about_wrapper">
                            <figure class="mb-0 about-image1">
                                <img src="./assets/images/about-image1.jpg" alt="" class="" />
                            </figure>
                            <figure class="mb-0 about-image2">
                                <img src="./assets/images/about-image2.jpg" alt="" class="img-fluid" />
                            </figure>
                            <div class="image-circle">
                                <div class="years">
                                    <span class="text-white">10</span>
                                    <sup class="text-white mb-0 plus">+</sup>
                                    <span class="text-white text">Years <br/>of Experience</span>
                                </div>
                                <figure class="mb-0 about-imagecircle">
                                    <img src="./assets/images/about-imagecircle.png" alt="" class="" />
                                </figure>
                            </div>
                            {/* <figure class="mb-0 about-dot">
                        <img src="./assets/images/about-dot.png" alt="" class="img-fluid"/>
                    </figure> */}
                            <figure class="mb-0 about-leftcircle">
                                <img src="./assets/images/about-leftcircle.png" alt="" class="img-fluid" />
                            </figure>
                            <figure class="mb-0 about-layer">
                                <img src="./assets/images/about-layer.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1" >
                        <div class="about_content" >
                            <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>About us</h6>
                            <h2 class="text-white">We help clients invent their future.</h2>

                            <p class="text2"> Let us be your partner in turning aspirations into achievements, as we embark on this journey of invention and transformation together.</p>
                            <a class="read_more text-white text-decoration-none" href="/about-us">Read More
                                <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <figure class="mb-0 about-rightimage">
                <img src="./assets/images/about-rightimage.png" alt="" class="img-fluid" />
            </figure>
            <figure class="mb-0 about-rightcircle">
                <img src="./assets/images/about-rightcircle.png" alt="" class="img-fluid" />
            </figure>
        </section>
    );
}


export default AboutSection;
