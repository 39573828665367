import React from "react";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
import ScrollToTopButton from "../Pages/ScrollToTopButton";
import { Link } from 'react-router-dom';

function ServiceSection() {

    return (

        <section class="service-section" style={{ padding: "20px" }} id="service-section">
            <figure class="service-leftlayer mb-0">
                <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid" />
            </figure>
            <figure class="service-dotimage mb-0">
                <img src="./assets/images/service-dotimage.png" alt="" class="img-fluid" />
            </figure>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="service_contentbox">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <div class="service-box ">
                                        <figure class="service-marketicon">
                                            <img src="./assets/images/service-marketicon.png" alt="" class="img-fluid" />
                                        </figure>
                                        <h4>UI/UX Design</h4>
                                        <p>
                                            Designing Experiences That Speak Louder Than Words</p>
                                        <a class="arrow text-decoration-none" href="/our-services#ui-ux-section" >  <i class="circle fa-thin fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <div class="box-top">
                                        <div class="service-box ">
                                            <figure class="service-producticon">
                                                <img src="./assets/images/service-producticon.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Custom Software Development</h4>
                                            <p >
                                                Transforming Your Vision Into Tailored Digital Solutions</p>
                                            <a class="arrow text-decoration-none" href="/our-services#custom-section"> <i class="circle fa-thin fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="service-box">
                                        <figure class="service-designicon">
                                            <img src="./assets/images/service-designicon.png" alt="" class="img-fluid" />
                                        </figure>
                                        <h4>Web & Mobile App Development</h4>
                                        <p >
                                            Empowering Your Ideas To Thrive In The Digital World, Wherever You Go
                                        </p>
                                        <a class="arrow text-decoration-none" href="/our-services#webapp-section" >  <i class="circle fa-thin fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                                    <div class="box-top">
                                        <div class="service-box">
                                            <figure class="service-dataicon">
                                                <img src="./assets/images/service-dataicon.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Software Testing & QA</h4>
                                            <p >
                                                Elevating Software Quality Through Meticulous Testing And Assurance
                                            </p>
                                            <a class="arrow text-decoration-none" href="/our-services#qa-section"><i class="circle fa-thin fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12" style={{ padding: 0, }}>
                        <div class="service_content position-relative " >

                            <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>Our Services</h6>
                            <h2>BEST IT SOLUTION FOR YOUR BUSINESS</h2>
                            <p>Catering to Your Unique Needs:</p>
                            <ul class="list-unstyled mb-0">
                                <li class="text mb-3"><i class="circle fa-duotone fa-check"></i> At Manomay Techlabs, We Offer a Comprehensive Range Of IT Solutions Tailored To Meet The Unique Needs Of Your Business.</li>
                                <li class="text mb-3"><i class="circle fa-duotone fa-check"></i> We Provide End-to-End IT Services to Ensure That Your Business Receives the Best Possible Solution at Every Stage.</li>
                                <li class="text mb-3"><i class="circle fa-duotone fa-check"></i>We Guarantee That Our IT Solutions Will Drive Efficiency, Productivity, and Growth for Your Business.</li>
                                <li class="text mb-3"><i class="circle fa-duotone fa-check"></i>Our Range of Services Are Meticulously Crafted to Not Only Meet but Exceed the Ever-Evolving Needs of Modern Enterprises.</li>
                            </ul>
                           <a class="get_started text-white text-decoration-none" href="/our-services">Get Started
                                <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <figure class="service-rightlayer mb-0">
                <img src="./assets/images/service-rightlayer.png" alt="" class="img-fluid" />
            </figure>
          
        </section>

    );
}


export default ServiceSection;
