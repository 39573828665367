import React from "react";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
function About() {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const handleMouseEnter1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const handleMouseEnter2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        setIsOpen3(false);
    };

    const handleMouseEnter3 = () => {
        setIsOpen3(true);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleMouseLeave = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
    };
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="aboutpage">

            <div class="sub-banner">
                <figure class="sub-bannerleftlayer mb-0">
                    <img src="./assets/images/sub-bannerleftlayer.png" alt="" class="img-fluid" />
                </figure>
                <header className={`header header-scrolled`} style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                    <div class="main-header">
                        <div class="container-fluid">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <a class="navbar-brand" href="./"><figure class="mb-0 banner-logo">
                                    <img src="./assets/images/logo.png" alt="" class="img-fluid" /></figure></a>
                                <Dropdown drop="right-start" class="navbar-toggler collapsed"  >
                                    <Dropdown.Toggle class="dropdown-toggle" style={{ backgroundColor: "transparent", color: "transparent",  border: "none",cursor:"none",marginTop:-20  }} >
                                        <button class="navbar-toggler collapsed">
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%" }} >
                                        <Row style={{ padding: "20px" }}>
                                            <Col style={{ width: "100%" }}>
                                                <Dropdown.Item href="/about-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>About Us</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-services"><h6 style={{ color: "#fd7e14", padding: "10px" }}>What We Do</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-expertise"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Our Expertise</h6></Dropdown.Item>
                                                <Dropdown.Item href="/software-testing&QA"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Software Testing & QA</h6></Dropdown.Item>
                                                <Dropdown.Item href="/contact-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Contact Us</h6></Dropdown.Item>
                                            </Col>
                                        </Row>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div class="collapse navbar-collapse" style={{ justifyContent: "center", marginRight: "100px" }} id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" href="/about-us">About Us</a>
                                            {/* <a class="nav-link" href="/About">About</a> */}
                                        </li>
                                        <Dropdown show={isOpen1} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-services" style={{ textDecoration: "none", color: "inherit" }}>What We Do</a>
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu className="full-width-dropdown-menu" >

                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Services</h4>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Back-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-java-50.png" />JAVA</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-laravel-50.png" />Laravel</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-node-js-32.png" />Node JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-php-30.png" />PHP</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-python-50.png" />Python</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ruby.png" />ROR</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Mobile Apps</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-android-50.png" />Android</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-flutter-50.png" />Flutter</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icone-ios.png" />iOS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React Native</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Front-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-angular-50.png" />Angular JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-stack-50.png" />MEAN Stack Development</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }} >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>UI/UX Design</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-graphic-design-50.png" />Graphics Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ui-48.png" />UI-UX Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-web-50.png" />Web Design</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>CMS</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-wordpress-50.png" />WordPress</Dropdown.Item>
                                                    </Col>

                                                </Row>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown drop="right" show={isOpen2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-expertise" style={{ textDecoration: "none", color: "inherit" }}> Expertise</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Expertise</h4>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-e-commerce-64.png" />E-Commerce App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-education-50.png" />Education App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-fitness-app-64.png" />Fitness App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cash-app-50.png" />Finance & Banking App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-app-32.png" />Healthcare App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-insurance-50.png" />Insurance App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-remind-app-50.png" />On Demand App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" />Shopping App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-tracking-app-66.png" />Tracking App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-travel-diary-50.png" />Travel & Hotel App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-crm-58.png" />CRM</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-erp-transactions-66.png" />ERP</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cms-48.png" />CMS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-code-50.png" />Bespoke</Dropdown.Item>
                                                    </Col>
                                                </Row>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#expertise-section">Expertise</a>
                                    </li> */}
                                        <Dropdown drop="right" show={isOpen3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/software-testing&QA" style={{ textDecoration: "none", color: "inherit" }}>Software Testing & QA</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Software Testing & QA</h4>
                                                    <Col >
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Full Cycle Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-iot-sensor-50.png" />IoT App Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-mobile-app-64.png" />Mobile App Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cloud-setting-68.png" />Web & Cloud Apps Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Exploratory Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-rest-api-50.png" />API Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-performance-smartphone-50.png" />Performance & Load Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-manual-50.png" />Manual & Automation Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-browser-50.png" />Cross Browser & Device Compatibility Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Localization Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-saas-50.png" />SAAS Testing</Dropdown.Item>
                                                    </Col>
                                                </Row>


                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#quality-section">Quality Assurance</a>
                                    </li> */}
                                        <li class="nav-space nav-item">
                                            <a class="nav-link" href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                    {/* <div class="last_list">
                                    <figure class="nav-phoneimage mb-0"><img class="img-fluid" src="./assets/images/nav-phoneimage.png" alt="" /></figure>
                                    <div class="content">
                                        <p class="text-size-18 text-white">Call Anytime:</p>
                                        <a class="text-decoration-none last_list_atag" href="tel:+012(345)6789">+012 (345) 6789</a>
                                    </div>
                                </div> */}
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content">
                                    <figure class="about-rightcircle service-rightcircle mb-0">
                                        <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">About Us</h1>
                                    <p>"Empowering Success Through Innovation and Collaboration"</p>
                                    {/* <div class="box">
                                        <span class="mb-0">Home</span>
                                        <figure class="mb-0 arrow"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                        <span class="mb-0 box_span">About</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="./assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>
                <figure class="sub-bannerrightlayer mb-0">
                    <img src="./assets/images/sub-bannerrightlayer.png" alt="" class="img-fluid" />
                </figure>
            </div>

            <section class="aboutus-section">
                <figure class="offer-toplayer mb-0">
                    <img src="./assets/images/offer-toplayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
                            <div class="about_wrapper">
                                <figure class="mb-0 aboutus-image1">
                                    <img src="./assets/images/aboutus-image1.jpg" alt="" class="" />
                                </figure>
                                <figure class="mb-0 aboutus-image2">
                                    <img src="./assets/images/aboutus-image2.jpg" alt="" class="img-fluid" />
                                </figure>
                                <div class="image-circle">
                                    <div class="years">
                                        <span class="text-white">10</span>
                                        <sup class="text-white mb-0 plus">+</sup>
                                        <span class="text-white text">Years<br/>of Experience</span>
                                    </div>
                                    <figure class="mb-0 about-imagecircle">
                                        <img src="./assets/images/about-imagecircle.png" alt="" class="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1">
                            <div class="service_content position-relative">
                                <figure class="service-rightcircle mb-0">
                                    <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                </figure>
                                <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>About Us</h6>
                                <h2>Innovate Solution For Success</h2>
                                {/* <p>Grursus mal suada faci lisis lorem ipsum dolarorit more ion consectetur elit vesti at bulum nec odio aea the um ipsumm recreo that dolocons.</p> */}
                                <ul class="list-unstyled mb-0">
                                    <li class="text"><i class="circle fa-duotone fa-check"></i>Tailored Approach: We believe in customized solutions that address the unique challenges and opportunities of each client, fostering a path to success that's as unique as your business.</li>
                                    <li class="text"><i class="circle fa-duotone fa-check"></i>Cutting-Edge Technology: By staying at the forefront of technological advancements, we provide innovative solutions that anticipate future needs and drive sustainable growth.</li>
                                    <li class="text text1"><i class="circle fa-duotone fa-check"></i>Client-Centric Philosophy: Our dedication to client satisfaction guides every aspect of our work, ensuring that our solutions not only meet but exceed expectations, delivering tangible results and fostering long-term partnerships.</li>
                                </ul>
                                <a class="get_started text-white text-decoration-none" href="./">Get Started
                                    <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="offer-bottomlayer mb-0">
                    <img src="./assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
                </figure>
            </section>

            <section class="aboutwhyus-section" style={{ padding: "30px" }}>
                <figure class="service-rightlayer mb-0">
                    <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="offer_content">
                                <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>Our Achievements</h6>
                                <h2>some of Our Achievements</h2>
                                <p>Celebrating milestones that validate our commitment to innovation and client success.</p>
                            </div>
                        </div>
                    </div>
                    <div class="achievement_wrapper">
                        <div class="row">
                            <figure class="offer-circleimage mb-0">
                                <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                            </figure>
                            <div class="col-lg-4 col-md-3 col-sm-6 col-6">
                                <div class="achievement-box box2">
                                    <figure class="icon1 achievement-icon">
                                        <img src="./assets/images/achievement-clienticon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>50+</h3>
                                        <p class="text-size-18 mb-0">Satisfied Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-3 col-sm-6 col-6">
                                <div class="achievement-box box2">
                                    <figure class="icon1 achievement-icon">
                                        <img src="./assets/images/achievement-projecticon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>100+</h3>
                                        <p class="text-size-18 mb-0">Projects Completed</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-3 col-sm-6 col-6">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/achievement-teamicon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>10+</h3>
                                        <p class="text text-size-18 mb-0">Team Members</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <figure class="service-leftlayer mb-0">
                    {/* <img src="./assets/images/service-rightlayer.png" alt="" class="img-fluid" /> */}
                </figure>
            </section>

            {/* <section class="expert-section">
                <figure class="offer-toplayer mb-0">
                    <img src="./assets/images/offer-toplayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" data-aos="fade-right">
                                <h6>Expert people</h6>
                                <h2>our professional team members</h2>
                                <p class="text1">Grursus mal suada faci lisis lorem ipsum dolarorit more ion consectetur elit vesti at bulum nec odio aea the au ipsumm recreo that dolocons.</p>
                                <p class="text2">Lorem ipsum dolarorit moreion consectetur elit vestiau at bulum nec odio aea the aur.</p>
                                <a class="get_started text-white text-decoration-none" href="./team.html">Get Started
                                    <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_wrapper">
                                <figure class="offer-circleimage mb-0">
                                    <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                                </figure>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="team-box">
                                            <figure>
                                                <img src="./assets/images/team-image1.jpg" alt="" class="img-fluid" />
                                            </figure>
                                            <div class="content">
                                                <h4>David Bell</h4>
                                                <span class="text-size-18">Marketer</span>
                                                <ul class="list-unstyled mb-0">
                                                    <li class="icons"><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                    <li class="icons"><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                    <li class="icons"><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="team-box">
                                            <figure>
                                                <img src="./assets/images/team-image2.jpg" alt="" class="img-fluid" />
                                            </figure>
                                            <div class="content">
                                                <h4>Kevin Woods</h4>
                                                <span class="text-size-18">Developer</span>
                                                <ul class="list-unstyled mb-0">
                                                    <li class="icons"><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                    <li class="icons"><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                    <li class="icons"><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-downcontent">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="team-box">
                                                <figure>
                                                    <img src="./assets/images/team-image3.jpg" alt="" class="img-fluid" />
                                                </figure>
                                                <div class="content">
                                                    <h4>Georgia James</h4>
                                                    <span class="text-size-18">Developer</span>
                                                    <ul class="list-unstyled mb-0">
                                                        <li class="icons"><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                        <li class="icons"><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                        <li class="icons"><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="team-box">
                                                <figure>
                                                    <img src="./assets/images/team-image4.jpg" alt="" class="img-fluid" />
                                                </figure>
                                                <div class="content">
                                                    <h4>Alina James</h4>
                                                    <span class="text-size-18">Designer</span>
                                                    <ul class="list-unstyled mb-0">
                                                        <li class="icons"><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                        <li class="icons"><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                        <li class="icons"><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="offer-bottomlayer mb-0">
                    <img src="./assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
                </figure>
            </section> */}

            <br /><br />


        </div>
    );
}
export default About;
