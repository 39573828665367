import React from "react";
import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useEffect } from "react";
function Expertise() {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const handleMouseEnter1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const handleMouseEnter2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        setIsOpen3(false);
    };

    const handleMouseEnter3 = () => {
        setIsOpen3(true);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleMouseLeave = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
    };
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="Expertisepage">
            <div class="sub-banner">
                <figure class="sub-bannerleftlayer mb-0">
                    <img src="./assets/images/sub-bannerleftlayer.png" alt="" class="img-fluid" />
                </figure>
                <header className={`header header-scrolled`} style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                    <div class="main-header">
                        <div class="container-fluid">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <a class="navbar-brand" href="./"><figure class="mb-0 banner-logo"><img src="./assets/images/logo.png" alt="" class="img-fluid" /></figure></a>
                                <Dropdown drop="right-start" class="navbar-toggler collapsed"  >
                                    <Dropdown.Toggle class="dropdown-toggle" style={{ backgroundColor: "transparent", color: "transparent",  border: "none",cursor:"none",marginTop:-20  }} >
                                        <button class="navbar-toggler collapsed">
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%" }} >
                                        <Row style={{ padding: "20px" }}>
                                            <Col style={{ width: "100%" }}>
                                                <Dropdown.Item href="/about-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>About Us</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-services"><h6 style={{ color: "#fd7e14", padding: "10px" }}>What We Do</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-expertise"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Our Expertise</h6></Dropdown.Item>
                                                <Dropdown.Item href="/software-testing&QA"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Software Testing & QA</h6></Dropdown.Item>
                                                <Dropdown.Item href="/contact-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Contact Us</h6></Dropdown.Item>
                                            </Col>
                                        </Row>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div class="collapse navbar-collapse" style={{ justifyContent: "center", marginRight: "100px" }} id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" href="/about-us">About Us</a>
                                            {/* <a class="nav-link" href="/About">About</a> */}
                                        </li>
                                        <Dropdown show={isOpen1} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle className="drop-toggle" style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-services" style={{ textDecoration: "none", color: "inherit" }}>What We Do</a>
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu className="full-width-dropdown-menu" >

                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Services</h4>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Back-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-java-50.png" />JAVA</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-laravel-50.png" />Laravel</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-node-js-32.png" />Node JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-php-30.png" />PHP</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-python-50.png" />Python</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ruby.png" />ROR</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Mobile Apps</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-android-50.png" />Android</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-flutter-50.png" />Flutter</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icone-ios.png" />iOS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React Native</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Front-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-angular-50.png" />Angular JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-stack-50.png" />MEAN Stack Development</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }} >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>UI/UX Design</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-graphic-design-50.png" />Graphics Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ui-48.png" />UI-UX Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-web-50.png" />Web Design</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>CMS</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-wordpress-50.png" />WordPress</Dropdown.Item>
                                                    </Col>

                                                </Row>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown drop="right" show={isOpen2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-expertise" style={{ textDecoration: "none", color: "inherit" }}> Expertise</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Expertise</h4>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-e-commerce-64.png" />E-Commerce App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-education-50.png" />Education App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-fitness-app-64.png" />Fitness App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cash-app-50.png" />Finance & Banking App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-app-32.png" />Healthcare App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-insurance-50.png" />Insurance App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-remind-app-50.png" />On Demand App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" />Shopping App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-tracking-app-66.png" />Tracking App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-travel-diary-50.png" />Travel & Hotel App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-crm-58.png" />CRM</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-erp-transactions-66.png" />ERP</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cms-48.png" />CMS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-code-50.png" />Bespoke</Dropdown.Item>
                                                    </Col>
                                                </Row>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#expertise-section">Expertise</a>
                                    </li> */}
                                        <Dropdown drop="right" show={isOpen3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/software-testing&QA" style={{ textDecoration: "none", color: "inherit" }}>Software Testing & QA</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Software Testing & QA</h4>
                                                    <Col >
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Full Cycle Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-iot-sensor-50.png" />IoT App Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-mobile-app-64.png" />Mobile App Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cloud-setting-68.png" />Web & Cloud Apps Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Exploratory Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-rest-api-50.png" />API Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-performance-smartphone-50.png" />Performance & Load Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-manual-50.png" />Manual & Automation Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-browser-50.png" />Cross Browser & Device Compatibility Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Localization Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-saas-50.png" />SAAS Testing</Dropdown.Item>
                                                    </Col>
                                                </Row>


                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#quality-section">Quality Assurance</a>
                                    </li> */}
                                        <li class="nav-space nav-item">
                                            <a class="nav-link" href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                    {/* <div class="last_list">
                                    <figure class="nav-phoneimage mb-0"><img class="img-fluid" src="./assets/images/nav-phoneimage.png" alt="" /></figure>
                                    <div class="content">
                                        <p class="text-size-18 text-white">Call Anytime:</p>
                                        <a class="text-decoration-none last_list_atag" href="tel:+012(345)6789">+012 (345) 6789</a>
                                    </div>
                                </div> */}
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content" >
                                    <figure class="service-rightcircle mb-0">
                                        <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">Our Expertise</h1>
                                    <p>"Unleashing Excellence: Our Expertise, Your Success"</p>
                                    {/* <div class="box">
                                <span class="mb-0">Home</span>
                                <figure class="mb-0 arrow"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid"/></figure>
                                <span class="mb-0 box_span">Expertise</span>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="./assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>
                <figure class="sub-bannerrightlayer mb-0">
                    <img src="./assets/images/sub-bannerrightlayer.png" alt="" class="img-fluid" />
                </figure>
            </div>

            <section class="servicepage-section service-section">
                <figure class="service-leftlayer mb-0">
                    <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid" />
                </figure>
                <figure class="service-dotimage mb-0">
                    <img src="./assets/images/service-dotimage.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="servicepage_content" >
                                <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>Our Expertise</h6>
                                <h2> Where Knowledge Meets Innovation</h2>
                                <p>
                                    Crafting Solutions, Driving Success: Leveraging Our Expertise to Transform Your Business.</p>
                                <figure class="offer-circleimage mb-0">
                                    <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="service_contentbox">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2">
                                    <div class="item">
                                        <div class="service-box box-mb" >
                                            <figure class="expertise-icon">
                                                <img src="./assets/images/icon/icons8-e-commerce-64.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>E-Commerce App Development</h4>
                                            <p class="text-size-18">Unlock the Power of E-Commerce with Our Custom App Development Solutions<br/>&nbsp; </p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 ">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-education-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Education App Development</h4>
                                            <p class="text-size-18">Transforming Learning Experiences Through Innovative App Development Solutions<br/>&nbsp;
                                            </p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2">
                                    <div class="item">
                                        <div class="service-box box-mb">
                                        <figure class="expertise-icon">
                                                <img src="./assets/images/icon/icons8-fitness-app-64.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Fitness App Development</h4>
                                            <p class="text-size-18">Empower Your Fitness Journey: Transforming Health and Wellness with Our Custom App Solutions</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box" >
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-cash-app-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Finance & Banking App Development</h4>
                                            <p class="text-size-18">Seamlessly Navigate the Digital Banking Landscape with Our Custom App Development<br/>&nbsp;
                                            </p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">
                                                <img src="./assets/images/icon/icons8-medical-mobile-app-80.png" alt=""  class="img-fluid" />
                                            </figure>
                                            <h4>Healthcare App Development</h4>
                                            <p class="text-size-18">Transforming Healthcare: Enhancing Access, Efficiency, and Care Through Innovative App Development</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box box-mb">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-insurance-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Insurance App Development</h4>
                                            <p class="text-size-18">Securing Tomorrow, Today: Crafting Custom Insurance Apps for Enhanced Coverage and Convenience</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box box-mb" >
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-remind-app-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>On Demand App Development</h4>
                                            <p class="text-size-18">Instant Solutions, Endless Possibilities: Unlocking On-Demand Services with Tailored App Development</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Shopping App Development</h4>
                                            <p class="text-size-18">Elevate Your Retail Experience: Redefining Convenience and Choice with Personalized Shopping Apps</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-tracking-app-66.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Tracking App Development</h4>
                                            <p class="text-size-18">Empower Your Users with Seamless Tracking Solutions Tailored to Your Needs<br/>&nbsp;</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box box-mb" >
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-travel-diary-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Travel & Hotel App Development</h4>
                                            <p class="text-size-18">Your Journey, Elevated: Transforming Travel Experiences with Innovative App Solutions for Hotels and Tourism</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">
                                                <img src="./assets/images/icon/icons8-crm-58.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>CRM</h4>
                                            <p class="text-size-18">Building Relationships, Driving Growth: Empowering Businesses with Tailored CRM Solutions for Success</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-erp-transactions-66.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>ERP</h4>
                                            <p class="text-size-18">Streamline Your Business Operations with Our Tailored ERP Solutions<br/>&nbsp;</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">
                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-cms-48.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>CMS</h4>
                                            <p class="text-size-18"> Streamline Your Workflow with Our CMS Solutions<br/>&nbsp;</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-2 mt-4">

                                    <div class="item">
                                        <div class="service-box">
                                        <figure class="expertise-icon">

                                                <img src="./assets/images/icon/icons8-code-50.png" alt="" class="img-fluid" />
                                            </figure>
                                            <h4>Bespoke</h4>
                                            <p class="text-size-18">Your Vision, Our Mission: Crafting Tailored Solutions to Propel Your Business Forward</p>
                                            {/* <a class="arrow text-decoration-none" href="./service.html"><i class="circle fa-thin fa-arrow-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <figure class="service-rightlayer mb-0">
                    <img src="./assets/images/service-rightlayer.png" alt="" class="img-fluid" />
                </figure>
            </section >



            {/* <section class="consultation-section">
        <figure class="consultation-leftimage mb-0">
            <img src="./assets/images/service-consultationleftimage.png" alt="" class="img-fluid"/>
        </figure>
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-12 col-12">
                    <div class="consultation_content" >
                        <h6>WE ARE HERE TO guide you</h6>
                        <h2 class="text-white">NEED A CONSULTATION?</h2>
                        <p class="mb-0">Grursus mal suada faci lisis lorem ipsum dolarorit more ame ion consea odio aea the dumm recreo that dolocons.</p>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                    <div class="consultation_wrapper">
                        <figure class="service-rightcircle mb-0">
                            <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid"/>
                        </figure>
                        <a class="get_started button1 text-white text-decoration-none" href="./about.html">Get a Quote
                            <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid"/></figure>
                        </a>
                        <a class="get_started button2 text-white text-decoration-none" href="./contact.html">Contact Us
                            <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid"></img></figure>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

            {/* <div class="vedio-section">
        <figure class="service-rightlayer mb-0">
            <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid"/>
        </figure>
        <div class="container">
            <div class="row position-relative" >
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="position-relative">
                        <figure class="mb-0 servicepage-dotimage">
                            <img src="./assets/images/service-dotimage.png" alt="" class="img-fluid"/>
                        </figure>
                        <figure class="mb-0 service-vedioimage">
                            <img src="./assets/images/service-vedioimage.jpg" alt="" class="img-fluid"/>
                        </figure>
                        <a class="popup-vimeo" href="https://video-previews.elements.envatousercontent.com/h264-video-previews/d1c81f1e-849f-4d45-ae57-b61c2f5db34a/25628048.mp4">
                            <div class="vedio-circle">
                                <figure class="mb-0 service-vedioicon">
                                    <img class="thumb img-fluid" style={{cursor: "pointer"}} src="./assets/images/service-vedioicon.png" alt=""/>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <figure class="service-leftlayer mb-0">
            <img src="./assets/images/service-rightlayer.png" alt="" class="img-fluid"/>
        </figure>
    </div> */}
            <br />
            <br />


        </div>
    );
}
export default Expertise;
