import React from "react";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from "react-bootstrap";
import { useState ,useEffect} from "react";
function TestingQA() {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const handleMouseEnter1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const handleMouseEnter2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        setIsOpen3(false);
    };

    const handleMouseEnter3 = () => {
        setIsOpen3(true);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleMouseLeave = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
    };
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="aboutpage">
            <div class="sub-banner">
                <figure class="sub-bannerleftlayer mb-0">
                    <img src="./assets/images/sub-bannerleftlayer.png" alt="" class="img-fluid" />
                </figure>
                <header className={`header header-scrolled`} style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                    <div class="main-header">
                        <div class="container-fluid">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <a class="navbar-brand" href="./"><figure class="mb-0 banner-logo">
                                    <img src="./assets/images/logo.png" alt="" class="img-fluid" /></figure></a>
                                <Dropdown drop="right-start" class="navbar-toggler collapsed"  >
                                    <Dropdown.Toggle class="dropdown-toggle" style={{ backgroundColor: "transparent", color: "transparent", border: 0 }} >
                                        <button class="navbar-toggler collapsed">
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%" }} >
                                        <Row style={{ padding: "20px" }}>
                                            <Col style={{ width: "100%" }}>
                                            <Dropdown.Item href="/about-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>About Us</h6></Dropdown.Item>
                                            <Dropdown.Item href="/our-services"><h6 style={{ color: "#fd7e14", padding: "10px" }}>What We Do</h6></Dropdown.Item>
                                            <Dropdown.Item href="/our-expertise"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Our Expertise</h6></Dropdown.Item>
                                            <Dropdown.Item href="/software-testing&QA"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Software Testing & QA</h6></Dropdown.Item>
                                            <Dropdown.Item href="/contact-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Contact Us</h6></Dropdown.Item>
                                            </Col>
                                        </Row>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div class="collapse navbar-collapse" style={{ justifyContent: "center", marginRight: "100px" }} id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" href="/about-us">About Us</a>
                                            {/* <a class="nav-link" href="/About">About</a> */}
                                        </li>
                                        <Dropdown show={isOpen1} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-services" style={{ textDecoration: "none", color: "inherit" }}>What We Do</a>
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu className="full-width-dropdown-menu" >

                                                <Row style={{ padding: "20px" }}>
                                                <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Services</h4>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Back-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-java-50.png" />JAVA</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-laravel-50.png" />Laravel</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-node-js-32.png" />Node JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-php-30.png" />PHP</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-python-50.png" />Python</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ruby.png" />ROR</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Mobile Apps</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-android-50.png" />Android</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-flutter-50.png" />Flutter</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icone-ios.png" />iOS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React Native</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Front-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-angular-50.png" />Angular JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-stack-50.png" />MEAN Stack Development</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }} >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>UI/UX Design</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-graphic-design-50.png" />Graphics Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ui-48.png" />UI-UX Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-web-50.png" />Web Design</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>CMS</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-wordpress-50.png" />WordPress</Dropdown.Item>
                                                    </Col>

                                                </Row>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown drop="right" show={isOpen2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-expertise" style={{ textDecoration: "none", color: "inherit" }}> Expertise</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Expertise</h4>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-e-commerce-64.png" />E-Commerce App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-education-50.png" />Education App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-fitness-app-64.png" />Fitness App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cash-app-50.png" />Finance & Banking App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-app-32.png" />Healthcare App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-insurance-50.png" />Insurance App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-remind-app-50.png" />On Demand App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" />Shopping App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-tracking-app-66.png" />Tracking App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-travel-diary-50.png" />Travel & Hotel App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-crm-58.png" />CRM</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-erp-transactions-66.png" />ERP</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cms-48.png" />CMS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-code-50.png" />Bespoke</Dropdown.Item>
                                                    </Col>
                                                </Row>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#expertise-section">Expertise</a>
                                    </li> */}
                                        <Dropdown drop="right" show={isOpen3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/software-testing&QA" style={{ textDecoration: "none", color: "inherit" }}>Software Testing & QA</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Software Testing & QA</h4>
                                                    <Col >
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Full Cycle Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-iot-sensor-50.png" />IoT App Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-mobile-app-64.png" />Mobile App Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cloud-setting-68.png" />Web & Cloud Apps Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Exploratory Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-rest-api-50.png" />API Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-performance-smartphone-50.png" />Performance & Load Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-manual-50.png" />Manual & Automation Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-browser-50.png" />Cross Browser & Device Compatibility Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Localization Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-saas-50.png" />SAAS Testing</Dropdown.Item>
                                                    </Col>
                                                </Row>


                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#quality-section">Quality Assurance</a>
                                    </li> */}
                                        <li class="nav-space nav-item">
                                            <a class="nav-link" href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                    {/* <div class="last_list">
                                    <figure class="nav-phoneimage mb-0"><img class="img-fluid" src="./assets/images/nav-phoneimage.png" alt="" /></figure>
                                    <div class="content">
                                        <p class="text-size-18 text-white">Call Anytime:</p>
                                        <a class="text-decoration-none last_list_atag" href="tel:+012(345)6789">+012 (345) 6789</a>
                                    </div>
                                </div> */}
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content">
                                    <figure class="about-rightcircle service-rightcircle mb-0">
                                        <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">Software Testing & QA</h1>
                                    <p>
                                        "Ensuring Quality, Reliability, and Performance - Software Testing & QA"</p>
                                    {/* <div class="box">
                                        <span class="mb-0">Home</span>
                                        <figure class="mb-0 arrow"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                        <span class="mb-0 box_span">About</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="./assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>

            </div>
            <div class="">
                    <div class="col-12">
                        <div class="offer_content">
                            <h6 style={{ color: "#fd7e14", textAlign: "center", fontSize: "25px" ,marginTop:"50px" }}>Software Testing & QA</h6>
                            {/* <h2>some of Our Achievements</h2> */}
                            {/* <p>Celebrating milestones that validate our commitment to innovation and client success. Explore some of our proudest achievements as we continue to push boundaries and set new benchmarks in the industry.</p> */}
                        </div>
                    </div>
                </div>

            <section class="whyus-section">

               
                <div class="container"  >
                    <div class="row" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/offer-icon1.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">IoT App Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ paddingTop: "5px" }}>
                                <p class="text1">IoT app testing involves assessing the functionality, security, and interoperability of
                                    applications designed for interconnected devices, ensuring seamless communication and reliable performance
                                    across various platforms and environments.</p>


                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row mobile-reverse" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ paddingTop: "5px" }} >
                                <p class="text1">
                                    Full cycle testing ensures comprehensive examination of software from inception to deployment,
                                    covering all stages of development to guarantee quality and reliability.</p>

                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding ">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/offer-icon2.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Full Cycle Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row" style={{  paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding ">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/mobile-test.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Mobile App Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    Mobile app testing involves evaluating the functionality, usability, performance, and security of mobile
                                    applications across different devices, platforms, and network conditions to ensure a seamless user experience and high-quality performance.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row mobile-reverse" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    SaaS (Software as a Service) testing focuses on assessing the functionality, scalability, security,
                                    and reliability of cloud-based software applications delivered over the internet,
                                    ensuring they meet user requirements and perform optimally in a multi-tenant environment.</p>

                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding ">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/saas.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">SAAS Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row" style={{paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/testing.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Exploratory Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">Exploratory testing is an agile testing approach where testers simultaneously design and execute tests, exploring the software's functionality with minimal pre-defined scripts.
                                    It emphasizes adaptability, creativity, and real-time learning.</p>


                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row mobile-reverse" style={{  paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    API testing involves assessing the functionality, reliability, performance, and security of application programming interfaces (APIs) by sending requests and analyzing
                                    responses to ensure they meet specifications and perform as expected.</p>

                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding ">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/api.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">API Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/performance.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Performance & Load Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    Performance testing evaluates how well a system performs under various conditions, while load testing assesses its ability to handle heavy user loads,
                                    ensuring optimal performance and scalability.</p>


                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row mobile-reverse" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    Manual testing involves human-driven testing, while automation testing uses software tools to execute
                                    predefined test scripts automatically, enhancing efficiency and repeatability.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding ">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/manual.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Manual & Automation Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/browser.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Cross Browser &<br /> Device Compatibility Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    Cross-browser and device compatibility testing involves verifying that a website or application functions correctly and appears as intended across different web browsers and devices,
                                    ensuring a consistent user experience regardless of the user's platform or device.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row mobile-reverse" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="">
                                    Localization testing ensures that a software product or application is adapted and culturally appropriate for a specific target market or locale,
                                    including language, currency, date formats, and cultural nuances, to provide an optimal user experience globally.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding ">
                            <div class="offer_boxcontent">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/testing.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Localization Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" style={{ marginTop: "50px" }}>
                    <div class="row" style={{ paddingBottom: "20px", justifyContent: "center", alignContent: "center" }}>
                        <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                            <div class="offer_boxcontent mt-3">
                                <div class="upper_portion">
                                    {/* <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure> */}
                                    <div class="image_content">
                                        <figure class="offer-icon" >
                                            <img src="./assets/images/cloud-test.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div class="lower_portion_wrapper">
                                    <div class="lower_portion">
                                        <h4 class="mb-0">Web & Cloud Apps Testing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_content" style={{ padding: "5px" }}>
                                <p class="text1">
                                    Web and cloud app testing involves evaluating the functionality, performance, security, and scalability of web-based and
                                    cloud-based applications to ensure
                                    they meet user requirements and deliver a seamless experience across different browsers, devices, and network conditions.</p>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <br /><br />


        </div>
    );
}
export default TestingQA;
