
import './App.css';
import Footer from './Component/Footer_Section';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import Mainpage from "./mainpage";
import Expertise from './Pages/Expertise';
import Contact from './Pages/ContactUs';
import Service from './Pages/service';
import TestingQA from './Pages/Testing_QA';
import ScrollToTopButton from "./Pages/ScrollToTopButton";
function App() {

  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<Mainpage />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/our-expertise" element={<Expertise />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/our-services" element={<Service />} />
        <Route exact path="/software-testing&QA" element={<TestingQA />} />
      </Routes>
 
      <Footer />
     <ScrollToTopButton/>
    </Router>

  );
}

export default App;
