import React from "react";
import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/owl.carousel.min.css"
import "../assets/css/owl.theme.default.min.css"
import "../assets/css/special-classes.css"


function ExpertiseSection() {

    return (
        <>
            <section class="expertise-section" id="expertise-section" style={{ paddingTop: "120px", paddingBottom: "100px" }}>
                <figure class="service-rightlayer mb-0">
                    <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid" style={{ marginRight: "2px" }} />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="achievement_content" data-aos="fade-right">
                                <figure class="offer-circleimage mb-0">
                                    <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" style={{ paddingTop: "120px", paddingBottom: "100px" }} />
                                </figure>
                                <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>Our Expertise</h6>
                                <h2> Where Knowledge Meets Innovation</h2>
                                <p> Our expertise lies at the intersection of profound knowledge and relentless innovation. With a dedicated team of seasoned professionals, we navigate the ever-evolving landscape of technology with confidence and precision.</p>
                                <a class="get_started text-white text-decoration-none" href="/our-services">Get Started
                                    <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="achievement_wrapper">
                                <div class="achievement-box box1">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/achievement-clienticon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>E-Commerce</h3>
                                        {/* <p class="text-size-18 mb-0">E-Commerce App Development</p> */}
                                    </div>
                                </div>
                                <div class="achievement-box box2">
                                    <figure class="icon1 achievement-icon">
                                        <img src="./assets/images/achievement-projecticon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>CMS</h3>
                                        {/* <p class="text-size-18 mb-0">Versatile CMS Platforms</p> */}
                                    </div>
                                </div>
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/achievement-teamicon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>CRM</h3>
                                        {/* <p class="text text-size-18 mb-0">Customer Centric CRM</p> */}
                                    </div>
                                </div>
                                <div class="achievement-box box4">
                                    <figure class="icon1 achievement-icon">
                                        <img src="./assets/images/icon/icons8-erp-transactions-66.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>ERP</h3>
                                        {/* <p class="text text-size-18 mb-0">Efficient ERP Solutionsn</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <figure class="service-leftlayer mb-0">
                    <img src="./assets/images/service-rightlayer.png" alt="" class="img-fluid" />
                </figure> */}
            </section>
            {/* <section class="expertise-section" id="expertise-section" style={{padding:"100px"}}>
    <figure class="service-rightlayer mb-0">
        <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid"/>
    </figure>
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                <div class="achievement_content">
                    <figure class="offer-circleimage mb-0">
                        <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid"/>
                    </figure>
                    <h6 style={{fontSize:"20px" ,color:"#fd7e14"}}>Our Expertise</h6>
                    <h2> Where Knowledge Meets Innovation</h2>
                    <p> "Our expertise lies at the intersection of profound knowledge and relentless innovation. With a dedicated team of seasoned professionals, we navigate the ever-evolving landscape of technology with confidence and precision.</p>
                    <a class="get_started text-white text-decoration-none" href="/our-expertise">Get Started
                        <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid"/></figure>
                    </a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                <div class="achievement_wrapper">
                    <div class="achievement-box box1">
                        <figure class="icon achievement-icon">
                            <img src="./assets/images/icon/icons8-e-commerce-64.png" alt="" class="img-fluid"/>
                        </figure> 
                        <div class="wrapper">
                            <h3>E-Commerce</h3>
                            <p class="mb-0" style={{fontSize:"13px"}}>E-Commerce App Development</p>
                        </div>
                    </div> 

              
                    <div class="achievement-box box2">
                        <figure class="icon1 achievement-icon">
                            <img src="./assets/images/icon/icons8-crm-58.png" alt="" class="img-fluid"/>
                        </figure>
                        <div class="wrapper">
                            <h5 class="mt-2">CRM</h5>
                            <p class="mb-0" style={{fontSize:"13px"}}>Customer Centric CRM</p>
                        </div>
                    </div>
                    <div class="achievement-box box3">
                        <figure class="icon achievement-icon">
                            <img src="./assets/images/icon/icons8-erp-transactions-66.png" alt="" class="img-fluid"/>
                        </figure> 
                        <div class="wrapper">
                            <div class="number number1">
                                <h5 class="value counter">ERP</h5>
                            
                            </div>
                            <p class="mb-0"  style={{fontSize:"13px"}}>Efficient ERP Solutions</p>
                        </div>
                    </div> 
                    <div class="achievement-box box4">
                        <figure class="icon1 achievement-icon">
                            <img src="./assets/images/icon/icons8-cms-48.png" alt="" class="img-fluid"/>
                        </figure>
                        <div class="wrapper">
                            <div class="number">
                                <h5 class="value counter">CMS</h5>
                              
                            </div>
                            <p class="mb-0"  style={{fontSize:"13px"}}>Versatile CMS Platforms</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <figure class="service-leftlayer mb-0">
        <img src="./assets/images/service-rightlayer.png" alt="" class="img-fluid"/>
    </figure>
</section> */}</>
    );
}


export default ExpertiseSection;
