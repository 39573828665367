import React from 'react';
import './App.css';
import Header from './Component/Header';
import ServiceSection from './Component/Service_Section';
import AboutSection from './Component/About_Section';
import ExpertiseSection from './Component/Expertise_Section';
import WhyChooseUs from './Component/WhyChooseUs';
import Quality from './Component/Quality_Section';
import Testimonial from './Component/Testimonial_Section';
import Footer from './Component/Footer_Section';
import ScrollToTopButton from './Pages/ScrollToTopButton';
export default function Mainpage() {


    return (
        <div>
            <Header />
            <ServiceSection />
            <ExpertiseSection />
            <WhyChooseUs />
            <Quality />      
            <AboutSection />
   
        </div>
    );
}