import React from "react";
import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/owl.carousel.min.css"
import "../assets/css/owl.theme.default.min.css"
import "../assets/css/special-classes.css"
import ScrollToTopButton from "../Pages/ScrollToTopButton";

function Footer() {

    return (
        <section class="footer-section" style={{ marginTop: "5px" }}>
            <div class="container">
          
                <div class="middle-portion">
                    <div class="row">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-12">
                            <div class="first-column">
                                <a href="./">
                                    <figure class="footer-logo">
                                        <img src="./assets/images/logo.png" class="img-fluid" alt="" />
                                    </figure>
                                </a>
                                <p class="text-size-18 footer-text">Elevating Businesses Through Innovative Solutions and Unparalleled Service.</p>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-3 col-sm-12 col-12 d-md-block d-none">
                            <div class="links list-pd">
                                <h4 class="heading text-white">Quick Links</h4>
                                <ul class="list-unstyled mb-0">

                                    <li><a href="/about-us" class="text-size-18 text text-decoration-none">About Us</a></li>
                                    <li><a href="/our-services" class="text-size-18 text text-decoration-none">What We Do</a></li>
                                    <li><a href="/our-expertise" class="text-size-18 text text-decoration-none">Our Expertise</a></li>
                                    <li><a href="/software-testing&QA" class="text-size-18 text text-decoration-none">Software Testing & QA</a></li>
                                    <li><a href="./contact-us" class="text-size-18 text text-decoration-none">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 d-sm-block d-none">
                            <div class="icons">
                                <div className="callus d-flex">
                                    <figure class="icon">
                                        <img src="./assets/images/footer-callicon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="content">
                                        <span class="heading text-white">Call us : </span>
                                        <br/>
                                        <a class="text-size-18 mb-0 text text-decoration-none " href="tel:+91 9328495731">+91 9328495731</a>
                                    </div>
                                </div>
                                <div className="emailus d-flex">
                                    <figure class="icon">
                                        <img src="./assets/images/footer-emailicon.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="content">
                                        <span class="text-white">Email us :  </span>
                                        <br/>
                                        <a href="mailto:info@manomaytechlabs.com" class="text-size-18  text-decoration-none">info@manomaytechlabs.com</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="copyright">
                    <div class="container">
                        <div class="row copyright-border">
                            <div class="col-lg-6 col-md-6 col-sm-12 co-12 column">
                                <p class="text-size-16">Copyright ©2024 Manomay TechLabs. All Rights Reserved.</p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 co-12 d-md-block d-none">
                        <div class="social-icons position-relative">
                            <ul class="list-unstyled position-absolute">
                                <li><a href="https://www.facebook.com/" class="text-decoration-none"><i class="fa-brands fa-facebook-f social-networks"></i></a></li>
                                <li><a href="https://in.linkedin.com/" class="text-decoration-none"><i class="fa-brands fa-linkedin social-networks"></i></a></li>
                            </ul>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
              
            </div>
  
        </section>
    );
}


export default Footer;
