import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/owl.carousel.min.css"
import "../assets/css/owl.theme.default.min.css"
import "../assets/css/special-classes.css"


function WhyChooseUs() {
    const [slideIndex, setSlideIndex] = useState(0);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        afterChange: (current) => {
            setSlideIndex(current);
        },
    };
    return (
        <><section class="whyus-section" id="whyus-section" style={{ paddingTop:"40px",paddingBottom:"50px"}} >
            <figure class="offer-toplayer mb-0">
                <img src="./assets/images/offer-toplayer.png" alt="" class="img-fluid" />
            </figure>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="offer_content">
                            <h6 style={{ fontSize: "20px", color: "#fd7e14" }}>Why Us</h6>
                            <h2>Why You Should Choose Us</h2>
                            <p> Choose Us for Unparalleled Reliability and Excellence in Every Aspect of Your Project.</p>
                        </div>
                    </div>
                </div>
                <div class="row position-relative">
                    <figure class="offer-circleimage mb-0">
                        <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                    </figure>
                    <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                        <div class="offer_boxcontent">
                            <div class="upper_portion">
                                <figure class="mb-0"><img src="./assets/images/offer-image1.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure>
                                <div class="image_content">
                                    <figure class="offer-icon" >
                                        <img src="./assets/images/offer-icon1.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div class="lower_portion_wrapper">
                                <div class="lower_portion">
                                    <h4 class="mb-0">Client First Always</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                        <div class="offer_boxcontent">
                            <div class="upper_portion">
                                <figure class="mb-0"><img src="./assets/images/offer-image2.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure>
                                <div class="image_content">
                                    <figure class="offer-icon offer-icon2">
                                        <img src="./assets/images/offer-icon2.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div class="lower_portion_wrapper">
                                <div class="lower_portion">
                                    <h4 class="mb-0">Timely Delivery</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4  col-sm-6 col-xs-12 article_padding mb-3">
                        <div class="offer_boxcontent mb-0">
                            <div class="upper_portion">
                                <figure class="mb-0"><img src="./assets/images/offer-image3.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure>
                                <div class="image_content">
                                    <figure class="offer-icon">
                                        <img src="./assets/images/icon/wrldwide.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div class="lower_portion_wrapper">
                                <div class="lower_portion">
                                    <h4 class="mb-0">Transparent Communication</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                        <div class="offer_boxcontent mb-0">
                            <div class="upper_portion">
                                <figure class="mb-0"><img src="./assets/images/5.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure>
                                <div class="image_content" >
                                    <figure class="offer-icon">
                                        <img src="./assets/images/icon/customer.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div class="lower_portion_wrapper">
                                <div class="lower_portion">
                                    <h4 class="mb-0">10+ Years of Expertise</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                        <div class="offer_boxcontent mb-0">
                            <div class="upper_portion">
                                <figure class="mb-0"><img src="./assets/images/6.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure>
                                <div class="image_content" >
                                    <figure class="offer-icon">
                                        <img src="./assets/images/icon/web.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div class="lower_portion_wrapper">
                                <div class="lower_portion">
                                    <h4 class="mb-0">50+ Clients Served</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12 article_padding mb-3">
                        <div class="offer_boxcontent mb-0">
                            <div class="upper_portion">
                                <figure class="mb-0"><img src="./assets/images/7.jpg" class="article_img" style={{ width: "100%", height: "100%" }} alt="" /></figure>
                                <div class="image_content" >
                                    <figure class="offer-icon">
                                        <img src="./assets/images/icon/price.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div class="lower_portion_wrapper">
                                <div class="lower_portion">
                                    <h4 class="mb-0">100+ Projects Completed</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <figure class="offer-bottomlayer mb-0">
                <img src="./assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
            </figure>
        </section>
        </>
      
    );
}


export default WhyChooseUs;
