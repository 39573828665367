import React from "react";
// import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Row, Col, } from "react-bootstrap";
import { useEffect } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
function Header() {

    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const handleMouseEnter1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const handleMouseEnter2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        setIsOpen3(false);
    };

    const handleMouseEnter3 = () => {
        setIsOpen3(true);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleMouseLeave = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 70) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className={`banner_outer ${isScrolled ? 'header-scrolled' : ''}`}>
            <header className={`header ${isScrolled ? 'header-scrolled' : ''}`} style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                <div class="main-header">
                    <div class="container-fluid">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <a class="navbar-brand" href="./"><figure class="mb-0 banner-logo"><img src="./assets/images/logo.png" alt="" class="img-fluid" /></figure></a>
                            <div>
                                <Dropdown drop="right-start">
                                    <Dropdown.Toggle class="mobile-view" style={{ backgroundColor: "transparent", color: "transparent", border: "none", cursor: "none", marginTop: -20 }} >
                                        <button class="navbar-toggler collapsed" style={{ boxShadow: "none" }}>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%" }} >
                                        <Row style={{ padding: "20px" }}>
                                            <Col style={{ width: "100%" }}>
                                                <Dropdown.Item href="/about-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>About Us</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-services"><h6 style={{ color: "#fd7e14", padding: "10px" }}>What We Do</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-expertise"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Our Expertise</h6></Dropdown.Item>
                                                <Dropdown.Item href="/software-testing&QA"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Software Testing & QA</h6></Dropdown.Item>
                                                <Dropdown.Item href="/contact-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Contact Us</h6></Dropdown.Item>
                                            </Col>
                                        </Row>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>


                            <div class="collapse navbar-collapse" style={{ justifyContent: "center", marginRight: "100px" }} id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <a class="nav-link" href="/about-us">About Us</a>
                                        {/* <a class="nav-link" href="/About">About</a> */}
                                    </li>
                                    <Dropdown show={isOpen1} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave} >
                                        <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                            <a href="/our-services" style={{ textDecoration: "none", color: "inherit" }}>What We Do</a>
                                        </Dropdown.Toggle >
                                        <Dropdown.Menu className="full-width-dropdown-menu" >
                                            <div >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Services</h4>
                                                    <Col >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Back-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-java-50.png" />JAVA</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-laravel-50.png" />Laravel</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-node-js-32.png" />Node JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-php-30.png" />PHP</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-python-50.png" />Python</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ruby.png" />ROR</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Mobile Apps</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-android-50.png" />Android</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-flutter-50.png" />Flutter</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icone-ios.png" />iOS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React Native</Dropdown.Item>
                                                    </Col>
                                                    <Col >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Front-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-angular-50.png" />Angular JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-stack-50.png" />MEAN Stack Development</Dropdown.Item>
                                                    </Col>
                                                    <Col >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>UI/UX Design</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-graphic-design-50.png" />Graphics Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ui-48.png" />UI-UX Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-web-50.png" />Web Design</Dropdown.Item>
                                                    </Col>
                                                    <Col >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>CMS</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-wordpress-50.png" />Wordpress</Dropdown.Item>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown drop="right" show={isOpen2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave} >
                                        <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                            <a href="/our-expertise" style={{ textDecoration: "none", color: "inherit" }}> Expertise</a>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="full-width-dropdown-menu" >
                                            <Row style={{ padding: "20px" }}>
                                                <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Expertise</h4>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-e-commerce-64.png" />E-Commerce App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-education-50.png" />Education App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-fitness-app-64.png" />Fitness App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cash-app-50.png" />Finance & Banking App Development</Dropdown.Item>
                                                </Col>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-app-32.png" />Healthcare App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-insurance-50.png" />Insurance App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-remind-app-50.png" />On Demand App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" />Shopping App Development</Dropdown.Item>
                                                </Col>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-tracking-app-66.png" />Tracking App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-travel-diary-50.png" />Travel & Hotel App Development</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-crm-58.png" />CRM</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-erp-transactions-66.png" />ERP</Dropdown.Item>
                                                </Col>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cms-48.png" />CMS</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-code-50.png" />Bespoke</Dropdown.Item>
                                                </Col>
                                            </Row>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#expertise-section">Expertise</a>
                                    </li> */}
                                    <Dropdown drop="right" show={isOpen3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave} >
                                        <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                            <a href="/software-testing&QA" style={{ textDecoration: "none", color: "inherit" }}>Software Testing & QA</a>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="full-width-dropdown-menu" >
                                            <Row style={{ padding: "20px" }}>
                                                <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}> Software Testing & QA</h4>
                                                <Col >
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Full Cycle Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-iot-sensor-50.png" />IoT App Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-mobile-app-64.png" />Mobile App Testing</Dropdown.Item>
                                                </Col>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cloud-setting-68.png" />Web & Cloud Apps Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Exploratory Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-rest-api-50.png" />API Testing</Dropdown.Item>
                                                </Col>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-performance-smartphone-50.png" />Performance & Load Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-manual-50.png" />Manual & Automation Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-browser-50.png" />Cross Browser & Device Compatibility Testing</Dropdown.Item>
                                                </Col>
                                                <Col>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Localization Testing</Dropdown.Item>
                                                    < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-saas-50.png" />SAAS Testing</Dropdown.Item>
                                                </Col>
                                            </Row>


                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#quality-section">Quality Assurance</a>
                                    </li> */}
                                    <li className={`nav-space ${isScrolled ? 'contactHoverscroll' : 'contactHover'}`}>
                                        <a class="nav-link " href="/contact-us">Contact Us</a>
                                    </li>
                                </ul>
                                {/* <div class="last_list">
                                    <figure class="nav-phoneimage mb-0"><img class="img-fluid" src="./assets/images/nav-phoneimage.png" alt="" /></figure>
                                    <div class="content">
                                        <p class="text-size-18 text-white">Call Anytime:</p>
                                        <a class="text-decoration-none last_list_atag" href="tel:+012(345)6789">+012 (345) 6789</a>
                                    </div>
                                </div> */}
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <figure class="banner-layerright mb-0">
                <img src="./assets/images/banner-layerright.png" class="img-fluid" alt="" />
            </figure>
            <section class="banner-section " style={{ marginTop: " 60px" }}>
                <div class="">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-sm-12 col-12" >
                            {/* <div class="social-icons position-relative" >
                                <ul class="list-unstyled position-absolute">
                                    <li><a href="" class="text-decoration-none"><i class="fa-brands fa-facebook-f social-networks"></i></a></li>
                                    <li><a href="" class="text-decoration-none"><i class="fa-brands fa-twitter social-networks"></i></a></li>
                                    <li><a href="" class="text-decoration-none"><i class="fa-brands fa-google-plus-g social-networks"></i></a></li>
                                    <li><a href="" class="text-decoration-none"><i class="fa-brands fa-instagram social-networks"></i></a></li>
                                </ul>
                            </div> */}
                            <div class="banner_content">
                                <figure class="banner-line mb-0"><img src="./assets/images/banner-line.png" alt="" class="img-fluid" /></figure>
                                <h6 class="text-white">WE PROVIDE OUTSOURCED</h6>
                                <h1 class="text-white">IT Services & Solutions</h1>
                                <p>We proactively manage your IT infrastructure to minimize downtime and maximize performance.</p>
                                <a class="get_started button1 text-white text-decoration-none" href="./#service-section">Get Started
                                    <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                </a>
                                {/* <a class="get_started button2 text-white text-decoration-none" href="/contact-us">Contact Us
                                    <figure class="mb-0"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                </a> */}
                                <figure class="banner-circleleft mb-0">
                                    <img src="./assets/images/banner-circleleft.png" class="img-fluid" alt="" />
                                </figure>
                                <figure class="banner-dotleft mb-0">
                                    <img src="./assets/images/banner-dotleft.png" class="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure class="mb-0 banner-image">
                                    <img src="./assets/images/banner-image.jpg" alt="" class="img-fluid" />
                                </figure>
                                {/* <div class="position-relative">
                                    <a class="popup-vimeo" href="https://video-previews.elements.envatousercontent.com/h264-video-previews/d1c81f1e-849f-4d45-ae57-b61c2f5db34a/25628048.mp4">
                                        <figure class="mb-0 banner-vedioimage">
                                            <img class="thumb img-fluid" style={{ cursor: "pointer" }} src="./assets/images/banner-vedioimage.png" alt="" />
                                        </figure>
                                    </a>
                                </div> */}
                                <figure class="banner-circleright mb-0">
                                    <img src="./assets/images/banner-circleright.png" class="img-fluid" alt="" />
                                </figure>
                                <figure class="banner-dotright mb-0">
                                    <img src="./assets/images/banner-dotright.png" class="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default Header;
