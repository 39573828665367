import React, { useState, useEffect } from "react";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/custom-style.css";
import "../assets/css/special-classes.css";


function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 500) { // Show button after scrolling 200 pixels
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className={`scroll-to-top-container ${isVisible ? 'visible' : ''}`}>
        <button
            className="scroll-to-top-button"
            onClick={scrollToTop}
        >
            <i className="fa fa-arrow-up"></i>
        </button>
    </div>
    );
}

export default ScrollToTopButton;
