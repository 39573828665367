import React from "react";
import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

function Contact() {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const handleMouseEnter1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const handleMouseEnter2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        setIsOpen3(false);
    };

    const handleMouseEnter3 = () => {
        setIsOpen3(true);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleMouseLeave = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
    };
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="contactpage">
            <div class="sub-banner ">
                <figure class="sub-bannerleftlayer mb-0">
                    <img src="./assets/images/sub-bannerleftlayer.png" alt="" class="img-fluid" />
                </figure>
                <header className={`header header-scrolled`} style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                    <div class="main-header">
                        <div class="container-fluid">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <a class="navbar-brand" href="./"><figure class="mb-0 banner-logo">
                                    <img src="./assets/images/logo.png" alt="" class="img-fluid" /></figure></a>
                                <Dropdown drop="right-start" class="navbar-toggler collapsed"  >
                                    <Dropdown.Toggle class="dropdown-toggle" style={{ backgroundColor: "transparent", color: "transparent", border: "none",cursor:"none",marginTop:-20 }} >
                                        <button class="navbar-toggler collapsed">
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%" }} >
                                        <Row style={{ padding: "20px" }}>
                                            <Col style={{ width: "100%" }}>
                                                <Dropdown.Item href="/about-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>About Us</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-services"><h6 style={{ color: "#fd7e14", padding: "10px" }}>What We Do</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-expertise"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Our Expertise</h6></Dropdown.Item>
                                                <Dropdown.Item href="/software-testing&QA"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Software Testing & QA</h6></Dropdown.Item>
                                                <Dropdown.Item href="/contact-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Contact Us</h6></Dropdown.Item>
                                            </Col>
                                        </Row>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div class="collapse navbar-collapse" style={{ justifyContent: "center", marginRight: "100px" }} id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" href="/about-us">About Us </a>
                                            {/* <a class="nav-link" href="/About">About</a> */}
                                        </li>
                                        <Dropdown show={isOpen1} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-services" style={{ textDecoration: "none", color: "inherit" }}>What We Do</a>
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu className="full-width-dropdown-menu" >

                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Services</h4>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Back-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-java-50.png" />JAVA</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-laravel-50.png" />Laravel</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-node-js-32.png" />Node JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-php-30.png" />PHP</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-python-50.png" />Python</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ruby.png" />ROR</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Mobile Apps</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-android-50.png" />Android</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-flutter-50.png" />Flutter</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icone-ios.png" />iOS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React Native</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Front-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-angular-50.png" />Angular JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-stack-50.png" />MEAN Stack Development</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }} >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>UI/UX Design</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-graphic-design-50.png" />Graphics Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ui-48.png" />UI-UX Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-web-50.png" />Web Design</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>CMS</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-wordpress-50.png" />WordPress</Dropdown.Item>
                                                    </Col>

                                                </Row>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown drop="right" show={isOpen2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-expertise" style={{ textDecoration: "none", color: "inherit" }}> Expertise</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Expertise</h4>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-e-commerce-64.png" />E-Commerce App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-education-50.png" />Education App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-fitness-app-64.png" />Fitness App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cash-app-50.png" />Finance & Banking App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-app-32.png" />Healthcare App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-insurance-50.png" />Insurance App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-remind-app-50.png" />On Demand App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" />Shopping App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-tracking-app-66.png" />Tracking App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-travel-diary-50.png" />Travel & Hotel App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-crm-58.png" />CRM</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-erp-transactions-66.png" />ERP</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cms-48.png" />CMS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-code-50.png" />Bespoke</Dropdown.Item>
                                                    </Col>
                                                </Row>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown drop="right" show={isOpen3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/software-testing&QA" style={{ textDecoration: "none", color: "inherit" }}>Software Testing & QA</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Software Testing & QA</h4>
                                                    <Col >
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Full Cycle Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-iot-sensor-50.png" />IoT App Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-mobile-app-64.png" />Mobile App Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cloud-setting-68.png" />Web & Cloud Apps Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Exploratory Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-rest-api-50.png" />API Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-performance-smartphone-50.png" />Performance & Load Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-manual-50.png" />Manual & Automation Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-browser-50.png" />Cross Browser & Device Compatibility Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Localization Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-saas-50.png" />SAAS Testing</Dropdown.Item>
                                                    </Col>
                                                </Row>


                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <li class="nav-space nav-item">
                                            <a class="nav-link" href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>

                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content" >
                                    <figure class="service-rightcircle mb-0">
                                        <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">Contact Us</h1>
                                    <p>
                                        "Let's Connect and Make Things Happen: Reach Out and Start Building Something Incredible Together."</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="./assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>
                <figure class="sub-bannerrightlayer mb-0">
                    <img src="./assets/images/sub-bannerrightlayer.png" alt="" class="img-fluid" />
                </figure>
            </div>

            <section class="contact-section">
                <figure class="offer-toplayer mb-0">
                    <img src="./assets/images/offer-toplayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row ">
                        <h6>Contact Info</h6>
                        <h2>Get in Touch</h2>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="contact_content" >
                                <div class="contact-box">
                                    <div class="box-image">
                                        <figure class="contact-location">
                                            <img src="./assets/images/contact-location.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                    <div class="box-content">
                                        <h4>Location:</h4>
                                        <p class="text">20, Amrakunj Society, Gayatri Temple Road, Patan, Gujarat - 384265</p>
                                    </div>
                                </div>
                                <div class="contact-box">
                                    <div class="box-image">
                                        <figure class="contact-email">
                                            <img src="./assets/images/contact-email.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                    <div class="box-content">
                                        <h4 class="heading">Email:</h4>

                                        <p>
                                            <a href="mailto:info@manomaytechlabs.com" class="mb-0 text-decoration-none">info@manomaytechlabs.com</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="contact-box box-mb">
                                    <div class="box-image">
                                        <figure class="contact-phone">
                                            <img src="./assets/images/contact-phone.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                    <div class="box-content">
                                        <h4 class="heading">Phone:</h4>

                                        <p>
                                            <a href="tel:+91 9328495731" class="mb-0 text-decoration-none text">+91 9328495731</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 contact-image">
                            <div class="contact-box box-mb">
                                <img src="./assets/images/contactus1.png"  class="img-fluid" style={{ height: "35rem", width: "35rem" }} />
                            </div>
                        </div>

                        {/* <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="need-section">
                                <figure class="offer-circleimage mb-0">
                                    <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                                </figure>
                                <div class="need_content">
                                    <h6 class="text-white">Get In Touch With Us</h6>
                                    <h2 class="text-white">send us a Message</h2>
                                    <form id="contactpage">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group mb-0">
                                                    <input type="text" class="form_style" placeholder="Your Name:" name="name" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group mb-0">
                                                    <input type="email" class="form_style" placeholder="Your Email:" name="emailid" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class=" form-group mb-0">
                                                    <textarea class="form_style" placeholder="Message" rows="3" name="msg"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="manage-button text-center">
                                            <button type="submit" class="send_now text-white text-decoration-none">Send Now
                                                <i class="circle fa-thin fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </form>
                                    <figure class="faq-image mb-0">
                                        <img src="./assets/images/faq-image.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <figure class="offer-bottomlayer mb-0">
                    <img src="./assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
                </figure>
            </section>

            <div class="contact_map_section">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7298.645361410955!2d72.1228650212288!3d23.842672834716844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c87fa1cd23f57%3A0x64f7d801f0c575a7!2sAmrakunj%20Society!5e0!3m2!1sen!2sin!4v1712741466789!5m2!1sen!2sin"
                            width="1920" height="556" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default Contact;
