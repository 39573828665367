import React from "react";
// import "../App.css";
import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/custom-style.css"
import "../assets/css/special-classes.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
function Service() {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const handleMouseEnter1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const handleMouseEnter2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        setIsOpen3(false);
    };

    const handleMouseEnter3 = () => {
        setIsOpen3(true);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleMouseLeave = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
    };
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="servicepage" >

            <div class="sub-banner">
                <figure class="sub-bannerleftlayer mb-0">
                    <img src="./assets/images/sub-bannerleftlayer.png" alt="" class="img-fluid" />
                </figure>
                <header className={`header header-scrolled`} style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                    <div class="main-header">
                        <div class="container-fluid">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <a class="navbar-brand" href="./"><figure class="mb-0 banner-logo">
                                    <img src="./assets/images/logo.png" alt="" class="img-fluid" /></figure></a>
                                <Dropdown drop="right-start" class="navbar-toggler collapsed"  >
                                    <Dropdown.Toggle class="dropdown-toggle" style={{ backgroundColor: "transparent", color: "transparent",  border: "none",cursor:"none",marginTop:-20  }} >
                                        <button class="navbar-toggler collapsed">
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%" }} >
                                        <Row style={{ padding: "20px" }}>
                                            <Col style={{ width: "100%" }}>
                                                <Dropdown.Item href="/about-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>About Us</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-services"><h6 style={{ color: "#fd7e14", padding: "10px" }}>What We Do</h6></Dropdown.Item>
                                                <Dropdown.Item href="/our-expertise"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Our Expertise</h6></Dropdown.Item>
                                                <Dropdown.Item href="/software-testing&QA"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Software Testing & QA</h6></Dropdown.Item>
                                                <Dropdown.Item href="/contact-us"><h6 style={{ color: "#fd7e14", padding: "10px" }}>Contact Us</h6></Dropdown.Item>
                                            </Col>
                                        </Row>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div class="collapse navbar-collapse" style={{ justifyContent: "center", marginRight: "100px" }} id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" href="/about-us">About Us</a>
                                            {/* <a class="nav-link" href="/About">About</a> */}
                                        </li>
                                        <Dropdown show={isOpen1} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-services" style={{ textDecoration: "none", color: "inherit" }}>What We Do</a>
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu className="full-width-dropdown-menu" >

                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Services</h4>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Back-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-java-50.png" />JAVA</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-laravel-50.png" />Laravel</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-node-js-32.png" />Node JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-php-30.png" />PHP</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-python-50.png" />Python</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ruby.png" />ROR</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Mobile Apps</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-android-50.png" />Android</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-flutter-50.png" />Flutter</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icone-ios.png" />iOS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React Native</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>Front-end</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-angular-50.png" />Angular JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-react-48.png" />React JS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-stack-50.png" />MEAN Stack Development</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }} >
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>UI/UX Design</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-graphic-design-50.png" />Graphics Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-ui-48.png" />UI-UX Design</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-web-50.png" />Web Design</Dropdown.Item>
                                                    </Col>
                                                    <Col style={{ width: "100%" }}>
                                                        <h6 style={{ color: "#fd7e14", padding: "10px" }}>CMS</h6>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-wordpress-50.png" />WordPress</Dropdown.Item>
                                                    </Col>

                                                </Row>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown drop="right" show={isOpen2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/our-expertise" style={{ textDecoration: "none", color: "inherit" }}> Expertise</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Our Expertise</h4>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-e-commerce-64.png" />E-Commerce App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-education-50.png" />Education App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-fitness-app-64.png" />Fitness App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cash-app-50.png" />Finance & Banking App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-app-32.png" />Healthcare App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-insurance-50.png" />Insurance App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-remind-app-50.png" />On Demand App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-amazon-shopping-app-50.png" />Shopping App Development</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-tracking-app-66.png" />Tracking App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-travel-diary-50.png" />Travel & Hotel App Development</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-crm-58.png" />CRM</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-erp-transactions-66.png" />ERP</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cms-48.png" />CMS</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-code-50.png" />Bespoke</Dropdown.Item>
                                                    </Col>
                                                </Row>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#expertise-section">Expertise</a>
                                    </li> */}
                                        <Dropdown drop="right" show={isOpen3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave} >
                                            <Dropdown.Toggle style={{ backgroundColor: "transparent", color: "white", border: 0, fontSize: "18px", fontWeight: 500 }} >
                                                <a href="/software-testing&QA" style={{ textDecoration: "none", color: "inherit" }}>Software Testing & QA</a>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width-dropdown-menu" >
                                                <Row style={{ padding: "20px" }}>
                                                    <h4 style={{ color: "#fd7e14", textAlign: "center", padding: "10px", textTransform: "uppercase" }}>Software Testing & QA</h4>
                                                    <Col >
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Full Cycle Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-iot-sensor-50.png" />IoT App Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-medical-mobile-app-64.png" />Mobile App Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cloud-setting-68.png" />Web & Cloud Apps Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Exploratory Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-rest-api-50.png" />API Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-performance-smartphone-50.png" />Performance & Load Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-manual-50.png" />Manual & Automation Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-browser-50.png" />Cross Browser & Device Compatibility Testing</Dropdown.Item>
                                                    </Col>
                                                    <Col>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-cheking-60.png" />Localization Testing</Dropdown.Item>
                                                        < Dropdown.Item className="drop-item" href="#"><img src="./assets/images/icon/icons8-saas-50.png" />SAAS Testing</Dropdown.Item>
                                                    </Col>
                                                </Row>


                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <li class="nav-space nav-item">
                                        <a class="nav-link" href="#quality-section">Quality Assurance</a>
                                    </li> */}
                                        <li class="nav-space nav-item">
                                            <a class="nav-link" href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                    {/* <div class="last_list">
                                    <figure class="nav-phoneimage mb-0"><img class="img-fluid" src="./assets/images/nav-phoneimage.png" alt="" /></figure>
                                    <div class="content">
                                        <p class="text-size-18 text-white">Call Anytime:</p>
                                        <a class="text-decoration-none last_list_atag" href="tel:+012(345)6789">+012 (345) 6789</a>
                                    </div>
                                </div> */}
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content">
                                    <figure class="about-rightcircle service-rightcircle mb-0">
                                        <img src="./assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">Our Services</h1>
                                    <p>"BEST IT SOLUTION FOR YOUR BUSINESS"</p>
                                    {/* <div class="box">
                                        <span class="mb-0">Home</span>
                                        <figure class="mb-0 arrow"><img src="./assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                                        <span class="mb-0 box_span">About</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="./assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>

            </div>

            <section class="aboutwhyus-section" style={{ padding: "30px", paddingBottom: 0 }}>
                <figure class="service-rightlayer mb-0">
                    <img src="./assets/images/service-leftlayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="offer_content">
                                <h6 style={{ color: "#fd7e14", textAlign: "center", fontSize: "25px" }}>Our Services</h6>
                                {/* <h2>some of Our Achievements</h2> */}
                                {/* <p>Celebrating milestones that validate our commitment to innovation and client success. Explore some of our proudest achievements as we continue to push boundaries and set new benchmarks in the industry.</p> */}
                            </div>
                        </div>
                    </div>

                    <div class="achievement_wrapper">
                        <div class="row">
                            <figure class="offer-circleimage mb-0">
                                <img src="./assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                            </figure>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box1">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-java-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>JAVA</h3>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box2">
                                    <figure class="icon1 achievement-icon">
                                        <img src="./assets/images/icon/icons8-laravel-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                        <h3>Laravel</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-node-js-32.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>Node JS</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-php-30.png" style={{ height: "50px", width: "50px" }} alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>PHP</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-python-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3 >Python</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <div>
                                            <img src="./assets/images/icon/icons8-ruby.png" alt="" class="img-fluid" />
                                        </div>
                                    </figure>
                                    <div class="wrapper">
                                            <h3>ROR</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-android-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>Android</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-flutter-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>Flutter</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icone-ios.png" style={{ height: "60px", width: "60px" }} alt="" class="img-fluid" />

                                    </figure>
                                    <div class="wrapper">
                                            <h3>iOS</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-react-48.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>React Native</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-angular-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>Angular JS</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        < img src="./assets/images/icon/icons8-react-48.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>React JS</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-stack-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>MEAN Stack Development</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-graphic-design-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>Graphics Design</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 ">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-ui-48.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>UI-UX Design</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-web-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>Web Design</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4">
                                <div class="achievement-box box3">
                                    <figure class="icon achievement-icon">
                                        <img src="./assets/images/icon/icons8-wordpress-50.png" alt="" class="img-fluid" />
                                    </figure>
                                    <div class="wrapper">
                                            <h3>WordPress</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section class="expert-section" style={{ paddingTop: 0 }}>
                <div class="container" id="ui-ux-section" style={{ paddingTop: "100px" }}>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_wrapper">
                                <div class="row" >
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <figure class="" >
                                            <img src="./assets/images/ui-ux.png" alt="" style={{ width: "500px" }} class="img-fluid" />
                                        </figure>
                                        <div class="content">
                                            <h4>UI/UX Design</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 " style={{ alignContent: "center" }}>
                            <div class="" style={{ padding: "0px" }}>
                                <p class="text1">Elevating User Experiences. Through meticulous research, thoughtful prototyping, and seamless integration, </p>
                                <p class="text2">UI/UX designers create intuitive interfaces that delight and engage users. By understanding user behaviors and needs,</p>
                                <p class="text2">they craft visually stunning and functionally intuitive experiences across digital platforms, fostering satisfaction and loyalty.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" id="webapp-section" style={{ paddingTop: "100px" }}>
                    <div class="row mobile-reverse">

                        <div class="col-lg-6 col-md-12 col-sm-12 col-12" style={{ alignContent: "center" }}>
                            <div class="" style={{ padding: "0px" }}>
                                <p class="text1">
                                  Innovating Digital Experiences
                                    Harnessing cutting-edge technologies and agile methodologies,</p>
                                <p class="text1">developers craft intuitive interfaces and robust architectures for web browsers and mobile devices alike. </p>
                                <p class="text2">From responsive web designs to native mobile apps, this dynamic field drives engagement, efficiency, and connectivity in the digital age.</p>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_wrapper">

                                <div class="row" id="webapp-section">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                        <figure class="" >
                                            <img src="./assets/images/webapp.jpg" style={{ width: "500px" }} alt="" class="img-fluid" />
                                        </figure>
                                        <div class="content">
                                            <h4>Web & Mobile App Development</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container " id="custom-section" style={{ paddingTop: "100px" }}>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_wrapper">

                                <div class="row" id="custome-section">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <figure class="" >
                                            <img src="./assets/images/custome.png" style={{ width: "500px" }} alt="" class="img-fluid" />
                                        </figure>
                                        <div class="content">
                                            <h4>Custom Software Development</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12" style={{ alignContent: "center" }}>
                            <div class="" style={{ padding: "0px" }}>
                                <p class="text1">Tailoring Solutions for Unique Needs. Leveraging expertise in programming languages and technologies,</p>
                                <p class="text2">Custom software developers design and build bespoke solutions to address specific business challenges By collaborating closely with clients,
                                    they ensure that the software aligns perfectly with organizational objectives, enhancing efficiency, productivity, and competitive advantage.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" id="qa-section" style={{ paddingTop: "100px" }}>
                    <div class="row mobile-reverse">

                        <div class="col-lg-6 col-md-12 col-sm-12 col-12" style={{ alignContent: "center" }}>
                            <div class="" style={{ padding: "0px" }} >

                                <p class="text1">Ensuring Reliability and Performance Excellence. With meticulous attention to detail and comprehensive testing strategies,
                                    QA professionals validate software functionality and performance across various scenarios.</p>
                                <p class="text2"> From automated tests to manual inspections, they strive to identify and rectify defects early in the development lifecycle,
                                    ensuring a seamless user experience and minimizing risks associated with bugs and errors.</p>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="expert_wrapper">

                                <div class="row" id="QA-section">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <figure class="" >
                                            <img src="./assets/images/qa.png" style={{ width: "500px" }} alt="" class="img-fluid" />
                                        </figure>
                                        <div class="content">
                                            <h4>Software Testing & QA</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="offer-bottomlayer mb-0">
                    <img src="./assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
                </figure>
            </section >

            <br /><br />


        </div >
    );
}
export default Service;
